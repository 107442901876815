<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('editpass.titlepay')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row style="margin-top:20px">
        <van-cell-group>
          <van-field
            v-model="form.old_password"
            :type="type"
            :right-icon="eye"
            :placeholder="$t('editpass.payhiti1')"
            @click-right-icon="openeye"
          >
            <template #left-icon>
              <van-icon class="iconfont" class-prefix="icon" name="mima" />
            </template>
          </van-field>

          <van-field
            v-model="form.password"
            :type="type"
            :right-icon="eye"
            :placeholder="$t('editpass.payhiti2')"
            @click-right-icon="openeye"
            maxlength="6"
          >
            <template #left-icon>
              <van-icon class="iconfont" class-prefix="icon" name="mima" />
            </template>
          </van-field>
          <van-field
            v-model="form.password2"
            :type="type"
            :right-icon="eye"
            :placeholder="$t('editpass.payhiti3')"
            @click-right-icon="openeye"
            maxlength="6"
          >
            <template #left-icon>
              <van-icon class="iconfont" class-prefix="icon" name="mima" />
            </template>
          </van-field>
        </van-cell-group>
      </van-row>
      <van-button class="mybtn" @click="submit">{{
        $t("editpass.editbtn")
      }}</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      option: [],
      form: {
        old_password: "",
        password: "",
        password2: "",
      },
      type: "password",
      eye: "closed-eye",
      phonebtn: true,
      phonetime: 60,
      emailbtn: true,
      emailtime: 60,
    };
  },
  created() {},
  methods: {
    // 查看密码
    openeye(event) {
      if (this.type === "password") {
        this.eye = "eye";
        this.type = "text";
      } else {
        this.eye = "closed-eye";
        this.type = "password";
      }
    },

    // 修改密码
    async submit() {
      if (!this.form.old_password || !this.form.password2 || !this.form.password) {
        this.$toast.fail(this.$t("editpass.complate"));
        return false;
      }
      if (this.form.password !== this.form.password2) {
        this.$toast.fail(this.$t("editpass.eqpass"));
        return false;
      }
      const { data } = await this.$http.post(
        "/home/home/changepaypass",
        this.form
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1.5rem;
    background: transparent !important;
  }
  /deep/.van-tab {
    font-size: 1rem !important;
  }
  /deep/.van-tab--active {
    color: #004ea3;
    font-weight: 500;
  }
  .van-button {
    font-size: 0.8rem;
    background-color: rgb(0, 78, 163);
    color: white;
    padding: 5px;
    border-radius: 6px;
    border: none;
  }
  .van-field {
    padding: 20px;
    font-size: 1rem;
    input {
      color: #000;
      padding-bottom: 0.53333rem;
      padding-left: 0.4rem;
      border-bottom: 0.02667rem solid #004ea3;
    }
  }
  /deep/.van-field__label {
    width: auto;
  }
  /deep/ .van-icon,
  /deep/.van-field__right-icon {
    color: #004ea3;
    font-size: 1.2rem;
  }
  .van-tab {
    font-size: 1rem;
  }
  .van-tab--active {
    color: #004ea3;
  }

  .van-icon-clear {
    color: #b3b3b3;
    font-size: 1rem;
  }
  .van-field__control {
    padding-left: 5px;
  }
  .mybtn {
    margin-top: 10%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.iconfont {
  color: #004ea3;
  font-size: 18px;
}
</style>
